var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Transactions ")]),(_vm.cardnumber)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.cardnumber)+" ")]):_vm._e(),_c('v-container',[_c('v-row',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"item-key":"transactionHeaderKey","show-expand":"","single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-sheet',{staticClass:"mb-6",attrs:{"color":"grey lighten-5","elevation":"2"}},[_c('v-container',[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"lg":"2","md":"6","sm":"6","cols":"12"}},[_c('v-card',{attrs:{"height":"120"}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"label":"Productgroup","items":_vm.productMainGroups,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.values.length - 1)+")")]):_vm._e()]}}]),model:{value:(_vm.productMainGroup),callback:function ($$v) {_vm.productMainGroup=$$v},expression:"productMainGroup"}})],1)],1)],1),_c('v-col',{attrs:{"lg":"3","md":"6","sm":"6","cols":"12"}},[_c('v-card',{attrs:{"height":"120"}},[_c('v-card-subtitle',[_vm._v(" Quantity range ")]),_c('v-card-text',[_c('v-range-slider',{staticClass:"align-center",attrs:{"max":5000,"min":0,"hide-details":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.quantityRange[0],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.$set(_vm.quantityRange, 0, $event)}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.quantityRange[1],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.$set(_vm.quantityRange, 1, $event)}}})]},proxy:true}]),model:{value:(_vm.quantityRange),callback:function ($$v) {_vm.quantityRange=$$v},expression:"quantityRange"}})],1)],1)],1),_c('v-col',{attrs:{"lg":"3","md":"6","cols":"12"}},[_c('v-card',{attrs:{"height":"120"}},[_c('v-card-subtitle',[_vm._v(" Date range ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"6","sm":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date from","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateFromMenu = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"lg":"6","md":"6","sm":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date to","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateToMenu = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"lg":"3","md":"12","cols":"12"}},[_c('v-text-field',{staticClass:"mr-4 mt-5",attrs:{"dense":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"lg":"1","md":"6","sm":"6","cols":"12"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-refresh ")]),_vm._v(" Refresh ")],1)],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-1",attrs:{"rounded":"","flat":"","color":"primary","dark":""}},[_c('v-simple-table',{staticClass:"pa-5",staticStyle:{"background-color":"rgba(0, 0, 0, 0)"},attrs:{"dense":""}},[_c('thead',[_c('td',[_vm._v("Product")]),_c('td',[_vm._v("Quantity")]),_c('td',[_vm._v("Amount")])]),_c('tbody',_vm._l((item.transactionDetails),function(t){return _c('tr',{key:t.detailNumber},[_c('td',[_vm._v(_vm._s(t.productName))]),_c('td',[_vm._v(_vm._s(t.quantity))]),_c('td',[_vm._v(_vm._s(t.posAmount))])])}),0)])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }