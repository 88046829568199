<template>
<div>
<v-container>
  <v-row dense justify="center">
  <v-card width="100%">
    <v-container>
    <v-row>
    <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
      
            <v-container>
              <v-row dense align="center">
                
         <v-col cols="1">
                   <v-btn color="info" small @click="showTransactions(null)" >
                     Transactions
              </v-btn>
         </v-col>
         <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="search"
                    label="Search on any card data"
                    class="mr-4"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
 <template v-slot:item.actions="{ item }">
      <v-btn x-small @click="showTransactions(item.cardnumberId)" color="primary">
        Transactions
      </v-btn>
    </template>         
        </v-data-table>
    </v-card-text>
    </v-row>
    </v-container>
  </v-card>
  </v-row>
</v-container>
<v-dialog v-model="showTransactionsDialog">
  <v-app-bar color="primary" dense dark>
    <v-spacer/>
    <v-icon @click="showTransactionsDialog = false">
      mdi-close
    </v-icon>
  </v-app-bar>
  <customertransactions :organizationNumber="organizationNumber" :cardnumber="selectedCard" :key="selectedCard"/>
</v-dialog>
</div>
</template>
<script>
import cimsService from "@/services/CimsService";
import customertransactions from "@/components/cims/CustomerTransactions"

export default {
  components: {
    customertransactions
  },
  props: {
    organizationNumber: { type: Number, default: null },
    segmentId: { type: Number, default: null },
  },
  data: () => ({
    loading: false,
    showTransactionsDialog: false,
    selectedCard: null,
    search: "",
    items: [],
    headers: [
            {
        text: "Account ID.",
        sortable: false,
        value: "customerAccountId",
        align: 'start'
      },  
            {
        text: "Customer ID",
        sortable: false,
        value: "customerId",
        align: 'start'
      },        
            {
        text: "Cardnumber",
        sortable: false,
        value: "cardnumberId",
        align: 'start'
      },    
            {
        text: "Card type",
        sortable: false,
        value: "cardtypeName",
        align: 'start'
      },    
            {
        text: "m3 YTD",
        sortable: false,
        value: "fuelVolumeYTD",
        align: 'start'
      },  
            {
        text: "Last fuel date",
        sortable: false,
        value: "fuelLastDate",
        align: 'start'
      },           
      { text: 'Actions', value: 'actions', sortable: false },                          
    ],
  }),
  computed:{
    
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.$store.dispatch("util/setLoading", {visible: true, text: "Fetching cards"});
      this.items = await cimsService.GetCards(this.organizationNumber);
      this.$store.dispatch("util/resetLoading");
    },
    showTransactions(val){
      this.selectedCard = val
      this.showTransactionsDialog = true
    }
  },
};
</script>