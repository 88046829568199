<template>
  <v-card
    color="primary lighten-2"
    dark
  >
    <v-card-text>
      <v-autocomplete
      dense
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="orgno"
        label="Search on name or org.no/ssn"
        placeholder="Sök"
        prepend-icon="mdi-account-search"
        return-object
        no-filter
      ></v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>

import cimsService from "@/services/CimsService";

  export default {
    data: () => ({
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
    }),

    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const name = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name

          return Object.assign({}, entry, { name })
        })
      },
    },

    watch: {
      search (val) {

      if (!val) {
        return
      }
      if (val.length <= 3) {
        return
      }
        this.fetchEntriesDebounced()
      },
      model(val) {
         this.$emit('input', val)  //notify parent of new value
    }
    },

    methods: {
      fetchEntriesDebounced() {
    // cancel pending call
    clearTimeout(this._timerId)

    // delay new call 500ms
    this._timerId = setTimeout(() => {
        this.SearchCustomer(this.search);
    }, 400)
  },

   async SearchCustomer(searchstring) {
      this.searchItems = [];
       this.isLoading = true
      const result = await cimsService.Search(searchstring);
       this.count = result.length
      this.entries = result
         this.isLoading = false

      }

    }
  }
</script>