<template>
  <v-card v-if="customer" rounded flat>
    <v-card-title>
      {{ customer.name }}
    </v-card-title>
    <v-card-subtitle>
      {{ customer.orgno }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col lg="3" md="4" sm="8" cols="12">
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Address</th>
                <td>{{ customer.address }}</td>
              </tr>
              <tr>
                <th>Postalcode</th>
                <td>{{ customer.postalcode }}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{{ customer.city }}</td>
              </tr>
              <tr>
                <th style="width: 100">Email</th>
                <td>{{ customer.email }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ customer.telephone }}</td>
              </tr>
              <tr>
                <th>Credit</th>
                <td>{{ customer.creditlimit  | formatMoney }}</td>
              </tr>
              <tr>
                <th>Registered</th>
                <td>{{ customer.registrationDate.substring(0, 10) }}</td>
              </tr>
              <tr>
                <th>Salesrep</th>
                <td>{{ customer.salesrep }}</td>
              </tr>
            </thead>
          </v-simple-table>
        </v-col>

        <!-- <v-col lg="3" md="3" sm="6" cols="12"> -->
        <v-col lg="8" md="8" cols="12">
          <v-row v-for="s in customer.segment" :key="s.segmentId">
            <v-col cols="12">
              <v-card width="100%" min-width="700">
                <v-row>
                  <v-col cols="1">
                    <div class="pa-5">
                      <v-img :src="segmentImageUrl(s.segmentId)">
                      </v-img>
              
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-simple-table dense>
                      <thead>
                        <tr>
                          <th>Segment</th>
                          <td>
                            <strong>
                              {{
                                s.segmentId == 1
                                  ? "SÅIFA"
                                  : s.segmentId == 2
                                  ? "Företag"
                                  : s.segmentId == 3
                                  ? "Privat"
                                  : ""
                              }}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <th>Rebate profile</th>
                          <td>{{ s.rebateId }}</td>
                        </tr>
                        <tr>
                          <th>Registered</th>
                          <td>{{ s.registrationDate.substring(0, 10) }}</td>
                        </tr>
                        <tr>
                          <th>Member level</th>
                          <td>{{ s.rewardLevel ? s.rewardLeve : "-" }}</td>
                        </tr>   
                        <tr>
                          <th>Member date</th>
                          <td>{{ s.rewardRegistrationDate ?  s.rewardRegistrationDate.substring(0, 10) : "-" }}</td>
                        </tr>                                                
                      </thead>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="2">
                    <v-card width="120" elevation="1" class="mx-2 my-2">
                      <v-card-title>
                        {{ s.fuelVolumeYTD/1000 | formatMoney }}
                      </v-card-title>
                      <v-card-subtitle> m3 YTD </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="2">
                    <v-card width="120" elevation="1" class="mx-2 my-2">
                      <v-card-title >
                        {{ s.fuelVolumeL12/1000 | formatMoney }}
                      </v-card-title>
                      <v-card-subtitle> m3 R12 </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="2">
                    <v-card width="120" elevation="1" class="mx-2 my-2">
                      <v-card-title>
                        {{ s.fuelVolumeLYTD/1000 | formatMoney }}
                      </v-card-title>
                      <v-card-subtitle> m3 LYTD </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <v-card-text>
      <v-row>
        <v-card width="120" elevation="1" class="mx-2 my-2">
          <v-card-title>
            {{ customer.fuelVolumeYTD | formatMoney }}
          </v-card-title>
          <v-card-subtitle> Volume YTD </v-card-subtitle>
        </v-card>
        <v-card width="120" elevation="1" class="mx-2 my-2">
          <v-card-title>
            {{ customer.fuelVolumeL12 | formatMoney }}
          </v-card-title>
          <v-card-subtitle> Volume R12 </v-card-subtitle>
        </v-card>
        <v-card width="120" elevation="1" class="mx-2 my-2">
          <v-card-title>
            {{ customer.fuelVolumeLYTD | formatMoney }}
          </v-card-title>
          <v-card-subtitle> Volume LYTD </v-card-subtitle>
        </v-card>
      </v-row>
    </v-card-text> -->
  </v-card>
</template>

<script>
import cimsService from "@/services/CimsService";

export default {
  props: {
    organizationNumber: { type: Number, default: null },
  },
  data: () => ({
    loading: false,
    customer: null,
  }),
  computed:{
      imagebaseurl(){
          return `${ process.env.VUE_APP_BASEURL}wwwassets/segments/`
      }
  },
  async mounted() {
    await this.fetchCustomer();
  },
  methods: {
    async fetchCustomer() {
      this.customer = await cimsService.GetCustomer(this.organizationNumber);
    },
    segmentImageUrl(val){
         return  this.imagebaseurl + (val == 1
                            ? "preem_saifa.png"
                            : val == 2
                            ? "preem_ftg.png"
                            : val == 3
                            ? "preem_privat.png"
                            : "")
    }
  },
};
</script>