<template>
<div>
  <v-sheet color="grey lighten-3" min-height="10vh">
    <v-container fill-height>
      <v-row dense justify="start" >
    <div class="transition-swing text-h4 mt-10 mb-2" >
    CIMS
    </div>
      </v-row>
      <v-row dense justify="start" >
        <v-col lg="4" md="6" sm="6" cols="12"> 
     <customersearch v-model="customer" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  <v-sheet elevation="5" color="grey lighten-5">
    <div v-if="customer">
          <v-container>
       <v-row justify="left" dense>
<v-col cols="12"> 
  <customercard :organizationNumber="customer.orgno" :key="customer.orgno" />
</v-col> 
       </v-row>
          </v-container>
    </div>
    </v-sheet>
  <v-sheet elevation="5" color="grey lighten-3">
    <div v-if="customer">
          <v-container>
       <v-row justify="left" dense>
<v-col cols="12"> 
  <customercards :organizationNumber="customer.orgno" :key="customer.orgno" />
</v-col> 
       </v-row>
          </v-container>
    </div>
    </v-sheet>    

</div>
</template>

<script>
import customersearch from "@/components/cims/CustomerSearch"
import customercard from "@/components/cims/CustomerCard"
import customercards from "@/components/cims/CustomerCards"

export default {
  components: {
    customersearch,
    customercard,
    customercards
  },
  data: () => ({
    customer: null,
    loading: false,
  }),
  computed: {

  },
  mounted() {

  },
  methods: {


  }
};
</script>