<template>
  <v-card>
    <v-card-title>
      Transactions
    </v-card-title>
    <v-card-subtitle v-if="cardnumber">
      {{ cardnumber }}
    </v-card-subtitle>
    <v-container>
      <v-row>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            class="elevation-1"
            item-key="transactionHeaderKey"
            show-expand
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template v-slot:top>
               <v-sheet color="grey lighten-5" elevation="2" class="mb-6">
              <v-container>
                <v-row dense align="center">
                  <v-col lg="2" md="6" sm="6" cols="12">
                    <v-card height="120">
                      <v-card-text>
                        <v-autocomplete
                        v-model="productMainGroup"
                          label="Productgroup"
                          :items="productMainGroups"
                          item-text="name"
                          item-value="id"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                              >(+{{ values.length - 1 }})</span
                            >
                          </template>
                        </v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col lg="3" md="6" sm="6" cols="12">
                    <v-card height="120">
                      <v-card-subtitle> Quantity range 
                       </v-card-subtitle>
                      
                      <v-card-text>
                        <v-range-slider
                          v-model="quantityRange"
                          :max="5000"
                          :min="0"
                          hide-details
                          class="align-center"
                          dense
                        >
                          <template v-slot:prepend>
                            <v-text-field
                              :value="quantityRange[0]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(quantityRange, 0, $event)"
                            ></v-text-field>
                          </template>
                          <template v-slot:append>
                            <v-text-field
                              :value="quantityRange[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(quantityRange, 1, $event)"
                            ></v-text-field>
                          </template>
                        </v-range-slider>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col lg="3" md="6" cols="12">
                    <v-card height="120">
                      <v-card-subtitle> Date range </v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col lg="6" md="6" sm="6" cols="12">
                            <v-menu
                              v-model="dateFromMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFrom"
                                  label="Date from"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateFrom"
                                @input="dateFromMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col lg="6" md="6" sm="6" cols="12">
                            <v-menu
                              v-model="dateToMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateTo"
                                  label="Date to"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateTo"
                                @input="dateToMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
              
                  <v-spacer></v-spacer>

                  <v-col lg="3" md="12" cols="12">
                    <v-text-field
                      dense
                      v-model="search"
                      label="Search"
                      class="mr-4 mt-5"
                      append-icon="mdi-magnify"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="1" md="6" sm="6" cols="12">
                    <v-btn small color="primary" @click="fetch">
                      <v-icon left small> mdi-refresh </v-icon>
                      Refresh
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
               </v-sheet>
            </template>
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length">
                <v-card rounded class="ma-1" flat color="primary" dark>
                  <v-simple-table
                    dense
                    class="pa-5"
                    style="background-color: rgba(0, 0, 0, 0)"
                  >
                    <thead>
                      <td>Product</td>
                      <td>Quantity</td>
                      <td>Amount</td>
                    </thead>
                    <tbody>
                      <tr
                        v-for="t in item.transactionDetails"
                        :key="t.detailNumber"
                      >
                        <td>{{ t.productName }}</td>
                        <td>{{ t.quantity }}</td>
                        <td>{{ t.posAmount }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import cimsService from "@/services/CimsService";

export default {
  props: {
    organizationNumber: { type: Number, default: null },
    cardnumber: { type: String, default: null },
  },
  data: () => ({
    loading: false,
    search: "",
    quantityRange: [0, 5000],
    dateFrom: null,
    dateFromMenu: false,
    dateTo: null,
    dateToMenu: false,
    productMainGroups: [],
    productMainGroup: [],
    items: [],
    expanded: [],
    headers: [
      {
        text: "Date",
        sortable: false,
        value: "transactionDatetime",
        align: "start",
      },
      {
        text: "Merchant",
        sortable: false,
        value: "merchantName",
        align: "start",
      },
      {
        text: "Cardnumber",
        sortable: false,
        value: "cardnumberId",
        align: "start",
      },
      {
        text: "Secondary cardnumber",
        sortable: false,
        value: "secondaryCardnumberId",
        align: "start",
      },
      {
        text: "Amount",
        sortable: false,
        value: "posAmount",
        align: "start",
      },
      {
        text: "Quantity",
        sortable: false,
        value: "quantity",
        align: "start",
      },
      {
        text: "Fuel Quantity",
        sortable: false,
        value: "fuelQuantity",
        align: "start",
      }
    ],
  }),
  computed: {},
  async mounted() {
    await this.getProductMainGroups();
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true
      this.items = await cimsService.GetTransactions(
        this.organizationNumber,
        this.cardnumber,
        this.dateFrom,
        this.dateTo,
        this.quantityRange[0],
        this.quantityRange[1],
        this.productMainGroup
      );
      this.loading = false
    },
    showCardTransactions(val) {
      alert(val);
    },
    async getProductMainGroups() {
      this.productMainGroups = await cimsService.GetProductMainGroups();
    },
  },
};
</script>